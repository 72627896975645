import axios from 'axios'

// 数据存储
const cache:any = {
  data: {},
  set (key:string, data:any) {
    this.data[key] = data
  },
  get (key:string) {
    return this.data[key]
  },
  clear (key:string) {
    delete this.data[key]
  }
}

// 建立唯一的key值
const buildUniqueUrl = (url:string, method:string, params = {}, data = {}) => {
  const paramStr = (obj:any) => {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
      return JSON.stringify(Object.keys(obj).sort().reduce((result:any, key) => {
        result[key] = obj[key]
        return result
      }, {}))
    } else {
      return JSON.stringify(obj)
    }
  }
  url += `?${paramStr(params)}&${paramStr(data)}&${method}`
  return url
}


// 防止重复请求
export default (options = {}) => async (config: any) => {
  const defaultOptions = {
    cacheTime: 0, // 缓存时间，设置为0，不清除缓存
    cache: false, // 是否支持缓存
    noWarn: false, // 是否有提示
    ...options
  }
  const index = buildUniqueUrl(config.url,config.method,config.params,config.data)
  let responsePromise = cache.get(index)
  if (!responsePromise || !defaultOptions.cache) {
    responsePromise = (async () => {
      try {
        const response = await (<any>axios.defaults.adapter)(config)
        return Promise.resolve(response)
      } catch (reason:any) {
        cache.clear(index)
        if (defaultOptions.noWarn) {
          reason.noWarn = true
        }
        return Promise.reject(reason)
      }
    })()
    cache.set(index, responsePromise)
    if (defaultOptions.cacheTime !== 0) {
      setTimeout(() => {
        cache.clear(index)
      }, defaultOptions.cacheTime)
    }
  }
  return responsePromise.then((data: any) => JSON.parse(JSON.stringify(data))) // 为防止数据源污染
}
