// 系统标识
const system_flag = 'citizen'

export function get_system_key (key:string) {
  return `${system_flag}_${key}`
}

export function removeSession (key = 'session') {
  localStorage.removeItem(get_system_key(key))
}

export function setSession (session:any, key = 'session') {
  localStorage.setItem(get_system_key(key), JSON.stringify(session))
}

export function getSession (key = 'session') {
  return JSON.parse(localStorage.getItem(get_system_key(key)) || 'null')
}
