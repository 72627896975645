// 数据格式化
import format from 'date-fns/format'

// 完整时间：yyyy-MM-dd HH:mm:ss
export function format_date (timestamp: string | number, str = 'yyyy-MM-dd') {
  return timestamp && format(+timestamp, str)
}

// 小数转百分比
export function format_decimal (number = 0, num = 2, scale = 100) {
  return +((+number * scale).toFixed(num))
}

// 处理金额
export function format_money (number = 0, num = 2, scale = 100) {
  return ((+number / scale).toFixed(num))
}

// 创建唯一id
export function create_guid() {
  return (Math.random()*10000000).toString(16).substring(0,4)+'-'+(new Date()).getTime()+'-'+Math.random().toString().substring(2,7)
}

// 数组转标签
export function arr_label(arr:any, props:any={}) {
  props = {
    label: 'label',
    value: 'value',
    children: 'children',
    re: true,
    unique: false, // 打上层级标签
    ...props
  }
  const obj:any = {}
  function re(arr:any=[], prefix:string) {
    if (arr && arr.length > 0) {
      for (const item of arr) {
        let key = item[props.value]
        if (props.unique) {
          key = `${prefix}_${key}`
        }
        obj[key] = item[props.label]
        if (props.re) re(item[props.children], key)
      }
    }
  }
  re(arr, '')
  return obj
}

// 数组转对象
export function arr_obj(arr:any, props:any={}) {
  props = {
    value: 'value',
    children: 'children',
    re: true,
    unique: false, // 打上层级标签
    ...props
  }
  const obj:any = {}
  function re(arr:any=[], prefix:string) {
    if (arr && arr.length > 0) {
      for (const item of arr) {
        let key = item[props.value]
        if (props.unique) {
          key = `${prefix}_${key}`
        }
        obj[key] = item
        if (props.re) re(item[props.children], key)
      }
    }
  }
  re(arr, '')
  return obj
}


// url参数
export function get_url_params (url = location.href) {
  const data:any = {}
  if (url.includes('?')) {
    const query = url.split('?')[1]
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      data[pair[0]] = pair[1]
    }
  }
  return data
}

