import axios from 'axios'
import qs from 'qs'
import { showNotify } from 'vant'
import router from '../router'
import defaultAdapter from './defaultAdapter'
import {encrypt, decrypt} from './aes'
import { getSession } from './session'

// 不加密的地址
const no_encrypt_url = GLOBAL_CONFIG.no_encrypt_url

// 只显示一个错误提示
let networkError = false
const warn = (message: string) => {
  if (!message || networkError) return
  networkError = true
  showNotify({
    type: 'danger',
    message,
    onClose() {
      networkError = false
    }
  })
}

export const baseURL = GLOBAL_CONFIG.backend_server
export const CancelToken = axios.CancelToken // 取消请求

const api = axios.create({
  baseURL,
  timeout: 0
})

// 添加请求拦截器
api.interceptors.request.use((config:any) => {
  // 带上用户token
  const authToken = getSession()
  if (authToken) {
    config.headers['Authorization'] = authToken.token
  }
  // 清除不需要的参数
  if (typeof config.params === 'object') {
    config.params = JSON.parse(JSON.stringify(config.params)) // 清除与原数据的引用关联
    for (const i in config.params) {
      if (config.params[i] === undefined ||
        config.params[i] === null ||
        config.params[i] === '') {
        delete config.params[i]
      }
    }
  }
  config.paramsSerializer = (params: any) => {
    return qs.stringify(params, {arrayFormat: 'comma'})
  }

  if (!config.defineAdapter){
    config.adapter = defaultAdapter({
      cacheTime: 1000,
      cache: true,
      ...config.defaultAdapterOptions
    })
  }
  // 加密
  if (config.data) {
    if (!no_encrypt_url.includes(config.url)) {
      config.data = encrypt(config.data)
    }
    config.headers['Content-Type'] = 'application/json'
  }
  return config
})

// 退出
export const logout = () => {
  router.push({ path: '/login' })
}

// 添加响应拦截器
api.interceptors.response.use(response => {
  // 解密
  if (!no_encrypt_url.includes(response.config.url)) {
    response.data = decrypt(response.data)
  }
  return response
}, (err) => {
  if (err && err.response) {
    // 解密
    if (!no_encrypt_url.includes(err.response.config.url)) {
      if (err.response.data) err.response.data = decrypt(err.response.data)
    }
    switch (err.response.status) {
    case 400:
      err.message = '请求错误'
      break

    case 401:
      err.message = '未授权，请登录'
      logout()
      break

    case 403:
      err.message = '拒绝访问'
      logout()
      break

    case 404:
      err.message = `请求地址出错: ${err.response.config.url}`
      break

    case 408:
      err.message = '请求超时'
      break

    case 500:
      err.message = '服务器内部错误'
      break

    case 501:
      err.message = '服务未实现'
      break

    case 502:
      err.message = '网关错误'
      break

    case 503:
      err.message = '服务不可用'
      break

    case 504:
      err.message = '网关超时'
      break

    case 505:
      err.message = 'HTTP版本不受支持'
      break

    default:
    }
  } else if (err.message === 'Network Error') {
    err.message = '网络错误，请稍后再试！'
  }
  if (err && err.response && err.response.data && err.response.data.message) {
    err.message = err.response.data.message
  }
  if (!err.noWarn) warn(err.message)
  return Promise.reject(err)
})

export default api
